// sets up the html title, favicon, and other <head> things per page
import React from 'react';
import Helmet from 'react-helmet';

// default value of title below, change default if need to
const H = ({title = "Jon Spewak"}) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>{ title }</title>
      <meta charset="utf-8" />
      <meta name="app-version" content="[[version]]" />
      <meta name="description" content="Jon Spewak" />
      <meta name="theme-color" content="#006356" />
      <meta name="description" content="Full-Stack Software Engineer specializing in scalability and human-centric development." />
      <meta name="keywords" content="Software Engineer, Design Thinking, React, ReactJS, Javascript, Node, NodeJS, MeteorJS, Full Stack, Database, Front End, Back End, contact Jon Spewak, MongoDB, DBA" />
      <meta name="author" content="Jon Spewak" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
    </Helmet>
  );
}
export default H;
