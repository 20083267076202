import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import spewakLogoSVG from '../images/svg/spewak-logo.svg';
import { CONTENT_WIDTH, SHOW_CONTACT } from '../functions/global';

const spacingBetween = 6;

const NavLink = styled(Link)`
  font-size: 20px;
  font-weight: ${props => props.fontWeight || "normal"};
  color: #ffffff;
  line-height: 1;
  padding: 6px;
  text-decoration: none;
  display: ${props => props.display || "inline"};
  text-align: ${props => props.textAlign || "left"};
  &:hover {
    text-shadow: 0px 1px #aaaaaa;
  }
`;
const ExternalLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-block;
  &:hover {
    text-shadow: 0px 1px #aaaaaa;
  }
`;

const cell  = css`width:50%;font-size:20px;color:#ffffff;`;
const cell2 = css`width:50%;font-size:20px;color:#ffffff;vertical-align:bottom;text-align:right;`;
const h1 = css`color:#ffffff;font-size: 20px;`
const Footer = () => {
  return (
    <footer
      css={css`background-color: #006356;padding-top:25px;padding-bottom:35px;`}
    >
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          max-width: 90vw;
          width: ${CONTENT_WIDTH}px;
        `}
      >
        <div className="Table"
          css={css`width:100%;`}
        >
          <div className="TableBody"><div className="TableRow">
            <div className="TableCell"
              css={cell}
            >
              <NavLink to="/" fontWeight="bold" display="inline-block"
              css={css`width:85px;position:relative;left:-15px;`}
              >
                <div>
                  <img src={ spewakLogoSVG }
                    width={ 75 } height={ 75 }
                  />
                </div>
              </NavLink>
              <div>Jon Spewak</div>
              <div>Copyright &copy; 2022</div>
            </div>
            <div className="TableCell"
              css={cell2}
            >
              <NavLink to="/privacy">
                privacy policy
              </NavLink>
              <div css={css`height:${spacingBetween}px;`}>&nbsp;</div>
              { ( SHOW_CONTACT
                &&
                <>
                  <NavLink to="/contact">
                    direct contact
                  </NavLink>
                  <div css={css`height:${spacingBetween}px;`}>&nbsp;</div>
                </>
              ) }
              <ExternalLink href="https://linkedin.com/in/spewak">
                connect on LinkedIn
              </ExternalLink>
            </div>
          </div></div>
        </div>
      </div>

    </footer>
  );
}
export default Footer;
